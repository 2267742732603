// Charge la base commune
import './common.js'

// Alert modal
async function getAlertModal () {
  import(/* webpackChunkName: "alertModal" */ './js/components/alertModal.js').then(() => {
    /* global Waypoint */
    Waypoint.refreshAll()
  })
}

// actions principales communes à toutes les pages
$(document).ready(function () {
  if ($('#alert-modal').length) { getAlertModal() }
})
